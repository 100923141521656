import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { OdataItems } from '@/shared/model/OdataItems';
import tradeChannel, { TradeChannel } from '@/shared/model/tradeChannel';
import tradeChannelImportSetting, { TradeChannelImportSetting } from '@/shared/model/tradeChannelImportSetting';
import { Organisation } from '@/shared/model/organisation';
import ImportTableView from '@/views/settings/company/account-assignment/import-table/import-table.vue';

const name = 'trade-channels-view';
const logger = new Logger(name);
const tradeChannelModule = namespace('tradeChannel');
const tradeChannelImportSettingModule = namespace('tradeChannelImportSetting');
const organizationModule = namespace('organization');

@Component({ name: name, components: { ImportTableView } })
export default class TradeChannelsView extends Vue {
  @tradeChannelImportSettingModule.Action('updateChangeClearBeforeImport')
  private updateChangeClearBeforeImport!: any;
  @tradeChannelImportSettingModule.Action('getTradeChannelImportSetting')
  private getTradeChannelImportSetting!: any;
  @tradeChannelImportSettingModule.Getter('getTradeChannelImportSetting')
  private tradeChannelImportSetting!: TradeChannelImportSetting;
  @tradeChannelImportSettingModule.Getter('getInProgressUpdateTradeChannelImportSetting')
  private inProgressUpdateTradeChannelImportSetting!: boolean;
  @tradeChannelImportSettingModule.Getter('getTradeChannelImportSettingsIsLoading')
  private tradeChannelImportSettingsIsLoading!: boolean;

  @tradeChannelModule.Action('uploadTradeChannel')
  private uploadTradeChannel!: any;
  @tradeChannelModule.Action('getTradeChannels')
  private getTradeChannels!: any;
  @tradeChannelModule.Getter('getTradeChannels')
  private tradeChannels!: OdataItems<TradeChannel>;
  @tradeChannelModule.Getter('getIsImporting')
  private isImporting!: boolean;

  @organizationModule.Getter('getOrganization')
  private organization!: Organisation;

  files = [];

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('column0'), value: 'value' },
      { text: this.$t('column1'), value: 'name' },
    ];
    return headers;
  }

  async mounted() {}
}
